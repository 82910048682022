import React from 'react';
import { NavLink } from 'react-router-dom';
import '../css/styles.css';

class Header extends React.Component {
    render() {
        return (
            // <div>
                <section id="header">
                    {/* Nav Bar */}
                    <nav className="navbar navbar-expand-lg navbar-dark">
                    <NavLink exact className="navbar-brand" to="/">
                        <img className="header-image" src="../images/Seamap_Logo.jpg" />
                        Seamap Group of Companies
                    </NavLink>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <NavLink exact className="nav-link" to="/">Home</NavLink>
                        </li>
                        <li className="nav-item dropdown">
                            <NavLink className="nav-link dropdown-toggle" to="/about-us" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            About Us
                            </NavLink>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <NavLink exact className="dropdown-item" to="/about-us/our-mission">Our Mission and Values</NavLink>
                            <NavLink exact className="dropdown-item" to="/about-us/what-we-do">What we do</NavLink>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <NavLink className="nav-link dropdown-toggle" to="/services"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Services
                            </NavLink>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <NavLink exact className="dropdown-item" to="/services/freight-forwarding">Freight Forwarding</NavLink>
                            <NavLink exact className="dropdown-item" to="/services/haulage">Haulage</NavLink>
                            <NavLink exact className="dropdown-item" to="/services/bonded-container-terminal">Bonded Container Terminal</NavLink>
                            </div>
                        </li>
                        <li className="nav-item">
                            <NavLink exact className="nav-link" to="/contact-us">Contact Us</NavLink>
                        </li>
                        </ul>
                    </div>
                    </nav>
                </section>
            // </div>
        )
    }
}

export default Header
import React from 'react';
import './App.css';
import './css/styles.css';
import Header from './common/header';
import Footer from './common/footer';
import Home from './pages/home';
import Contact from './pages/contact';
import WhatWeDo from './pages/whatwedo';
import OurMission from './pages/ourmission';
import Bct from './pages/bct';
import Haulage from './pages/haulage';
import FreightForwarding from './pages/freightForwarding';
import { Switch, Route } from 'react-router-dom';

class App extends React.Component {
  render() {
    return (
      <div className="App">
        {/* <section id="header"> */}
          <Header/>
        {/* </section> */}
        <section className="body">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/contact-us" component={Contact} />
          <Route exact path="/about-us/what-we-do" component={WhatWeDo} />
          <Route exact path="/about-us/our-mission" component={OurMission} />
          <Route exact path="/services/bonded-container-terminal" component={Bct} />
          <Route exact path="/services/haulage" component={Haulage} />
          <Route exact path="/services/freight-forwarding" component={FreightForwarding} />
        </Switch>
        </section>
        <section id="footer">
          <Footer></Footer>
        </section>
      </div>
    );
  }
}

export default App;
import React from 'react';
import '../css/styles.css';
import '../css/services.css';
import { HeaderImage } from '../common/carousel';
import { NavLink } from 'react-router-dom';

class Haulage extends React.Component {
    render() {
        return (
            <div>
                <section className="service">
                    <HeaderImage image="../images/services/haulage_banner.jpg" alt="about-us-img"/>

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item nonactive-page" aria-current="page"><NavLink exact className="breadcrumb-link" to="/"> Home</NavLink></li>
                            <li className="breadcrumb-item active-page" aria-current="page">Services - Haulage</li>
                        </ol>
                    </nav>

                    <section id="introduction">
                        <h1 id="haulage" className="services-header">
                        Haulage
                        </h1>
                        <div className="services-body">
                            <p>
                                Our haulage services is born out of the strategic need to
                                enhance the logistics and supply chain management support of
                                our operations, all in our determined quest to ensure client
                                satisfaction.
                            </p>
                            <p>
                                Seamap haulage operations is reputed for its safety,
                                reliability and timely services. These indices are achived by
                                operating a fleet of modern and serviceable trucks; employing
                                the services of dedicated and well trained drivers as well as
                                operational support staff and the adoption of innovative
                                management practices and technology.
                            </p>
                            <p>
                                Our fleet of trucks of various classifications (Flat-beds,
                                Covered, Trailers, 18-wheelers) ensures that your cargo
                                irrespective of weight or tonnage, containerized or not, with
                                special handlig requirements or not; will be delivered safely
                                and on time to any part of Nigeria.
                            </p>
                        </div>
                    </section>
                </section>
            </div>
        )
    }
}

export default Haulage;
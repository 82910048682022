import React from 'react';
import '../App.css';
import '../css/styles.css';
import { CarouselForPage } from '../common/carousel';
import { NavLink } from 'react-router-dom';

class Home extends React.Component {
    render() {
        return (
            <div className="home">   
              {/* Picture slides */}
              <section id="title">
                <CarouselForPage firstPicture={firstPicture} secondPicture={secondPicture} thirdPicture={thirdPicture} fourthPicture={fourthPicture} />
              </section>
      
              <section id="introduction">
                <div class="intro">
                  <h1 class="intro-header">Welcome!</h1>
                  <p>
                    Sea Map Group is one of the foremost freight fowarders in Nigeria and a top player in the Nigerian
                    Maritime Industry. Sea Map Nigeria Limited secured a Clearing and Forwarding Licence from the
                    Nigerian Customs Service in 1993.
                  </p>
      
                  <p>
                    Our backbone is the goodwill we have built with satisfied clients (Government Agencies,
                    Conglomerates and Corporate organisations, International Organisations and Agencies, Importers
                    and Exporters) over the years through prompt, reliable and efficient service delivery.
                  </p>
      
                  <p>
                    We have grown and diversified, becoming a formidable player in freight forwarding, Haulage and
                    Wharehousing services. In response to the need to decongest the ports and as our contribution to an
                    efficient Container and Cargo clearance in Nigeria, we secured a license to establish a
                    Bonded Container Terminal in our vast expanse of land located in Amuwo Odofin area of Lagos State.
                  </p>
      
                  <p>
                    Please feel free to browse through our website to learn more about us and our services. You may
                    perhaps want to contact us online and in real time through our contact us form. We will be very
                    glad to respond to your enquiries.
                  </p>
      
                  <p>
                    Thank you for paying us a visit.
                  </p>
                </div>
              </section>
      
              <section id="Services">
                <h1 class="services-header">Our Services</h1>
      
                <div class="row">
                  <div class="col-lg-4 service-box">
                    <img class="service-icon" src="../images/services/subHeaderShip.gif"></img>
                    <h3>
                      <NavLink exact className="service-name" to="/services/freight-forwarding">
                        Freight Forwarding<i class="fas fa-arrow-right"></i>
                      </NavLink>
                    </h3>
                    <p class="service-description">
                      Seamap Group is commited to render highly professional and personalised freight fowarding
                      services to our diverse clients through all Nigerian Seaports and Border Posts.
                    </p>
                  </div>
      
                  <div class="col-lg-4 service-box">
                    <img class="service-icon" src="../images/services/servCatHaulage.gif"></img>
                    <h3>
                      <NavLink exact className="service-name" to="/services/haulage">
                        Haulage<i class="fas fa-arrow-right"></i>
                      </NavLink>
                    </h3>
                    <p class="service-description">
                      Our haulage services is born out of the strategic need to enhance the logistics and supply chain
                      management support of our operations, all in our determined quest to ensure client satisfaction.
                    </p>
                  </div>
      
                  <div class="col-lg-4 service-box">
                    <img class="service-icon bct" src="../images/services/servCatTerminal.gif"></img>
                    <h3>
                      <NavLink exact className="service-name" to="/services/bonded-container-terminal">
                        Bonded Container Terminal<i class="fas fa-arrow-right"></i>
                      </NavLink>
                    </h3>
                    <p class="service-description">
                      Sea Map Nigeria Limited recently secured a provisional license to operate a bonded terminal at
                      Amuwo-Odofin Industrial Layout, Lagos State, Nigeria.
                    </p>
                  </div>
                </div>
              </section>
      
              <section id="affiliates">
                {/* <h1>Connect</h1> */}
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-lg-2 col-md-4">
                      <img class="affiliates-icon" src="../images/affiliates/ANLCA_Logo.png" alt="anlca" />
                      <p>
                        <a href="http://www.anlca.org.ng/" target="_blank">Association of Nigerian Licensed Customs Agents</a>
                      </p>
                    </div>
      
                    <div class="col-lg-2 col-md-4">
                      <img class="affiliates-icon" src="../images/affiliates/CRFFN_Logo.gif" alt="crffn" />
                      <p>
                        <a href="http://www.crffn.gov.ng/" target="_blank">The Council for the Regulation of Freight Forwarding in Nigeria</a>
                      </p>
                    </div>
      
                    <div class="col-lg-2 col-md-4">
                      <img class="affiliates-icon" src="../images/affiliates/logo_COTECNA.gif" alt="cotecna" />
                      <p>
                        <a href="http://www.cotecna.com/" target="_blank">Cotecna</a>
                      </p>
                    </div>
      
                    <div class="col-lg-2 col-md-4">
                      <img class="affiliates-icon" src="../images/affiliates/Logo_NEPC.gif" alt="nepc" />
                      <p>
                        <a href="https://nepc.gov.ng/" target="_blank">Nigerian Export Promotion Council</a>
                      </p>
                    </div>
      
                    <div class="col-lg-2 col-md-4">
                      <img class="affiliates-icon" src="../images/affiliates/NCS_logo_01.png" alt="ncs" />
                      <p>
                        <a href="http://www.customs.gov.ng/" target="_blank">Nigerian Customs Service</a>
                      </p>
                    </div>
      
                    <div class="col-lg-2 col-md-4">
                      <img class="affiliates-icon" src="../images/affiliates/NPA_Logo.jpg" alt="npa" />
                      <p>
                        <a href="http://www.nigerianports.org/" target="_blank">Nigerian Ports Authority</a>
                      </p>
                    </div>
      
                  </div>
                </div>
              </section>
      
              <section id="contact">
                <div class="container-fluid contact">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="address">
                        <h3>Address:</h3>
                        <p>Plot C23, Paache Close,</p>
                        <p>Amuwo-Odofin Industrial Layout,</p>
                        <p>Amuwo-Odofin</p>
                        <p>Lagos State, Nigeria.</p>
                      </div>
      
                      <div class="tel">
                        <h3>Tel No: </h3>
                        <p>234-17768413</p>
                      </div>
      
                      <div class="email">
                        <h3>Email:</h3>
                        <p>info@seamapgroup.com;  seamapgroup@yahoo.com</p>
                      </div>
                    </div>
      
                    <div class="col-md-6 map">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d440615.3368769347!2d3.0695097703014653!3d6.559297045681473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8eb54db44633%3A0xcfa6bc2ec0d284cd!2sPaache%20Cl%2C%20Amuwo%20Odofin%20Estate%2C%20Lagos%2C%20Nigeria!5e0!3m2!1sen!2suk!4v1593426667885!5m2!1sen!2suk" style={googleMapStyle} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    </div>
                  </div>
      
                  <div>
                    <NavLink exact to="/contact-us">
                      <button type="button" class="btn btn-lg btn-dark contact-button">Contact Form</button>
                    </NavLink>
                  </div>
                </div>
              </section>
            </div>
          );
    }
}

export default Home;

const googleMapStyle = {
    width: 700,
    height: 400,
    frameborder: 0,
    border: 0,
    allowFullscreen: "",
    ariaHidden: "false",
    tabindex: 0
  };
  
  const firstPicture = "../images/bannerImg_03.jpg"
  const secondPicture = "../images/bannerImg_05.jpg"
  const thirdPicture = "../images/bannerImg_06.jpg"
  const fourthPicture = "../images/bannerImg_07.jpg"
import React from 'react';
import '../css/styles.css';
import '../css/services.css';
import { HeaderImage } from '../common/carousel';
import { NavLink } from 'react-router-dom';

class FreightForwarding extends React.Component {
    render() {
        return (
            <div>
                <section className="service">
                    <HeaderImage image="../images/services/freightForwarding_banner.jpg" alt="about-us-img" />
                
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item nonactive-page" aria-current="page"><NavLink exact className="breadcrumb-link" to="/"> Home</NavLink></li>
                            <li className="breadcrumb-item active-page" aria-current="page">Services - Freight Forwarding</li>
                        </ol>
                    </nav>

                    <section id="introduction">
                        <h1 className="services-header">
                            Freight Forwarding
                        </h1>
                        <div className="services-body">
                            <p>
                                Seamap Group is commited to always render highly professional and
                                personalised freight fowarding services to our diverse clients through
                                all Nigerian Seaports and Border Posts. Our services are designed to save
                                you money, save you time, and save you untold potential hassles, worries
                                and burdens inherent in International trade with special emphasis on
                                import and export operations.
                            </p>
                            <p>
                                Consummate knowledge of the industry, expertise in supply chain
                                management, coupled with innovative corporate traditions guarantee that
                                we help our clients get their cargoes promptly to their precise
                                destination, with an assurance that they can go to bed without losing any
                                sleep. It makes no difference to us whether the cagoes are Dry-Bulk,
                                Groupage, Containerized or Wet-Bulk, Specialized Projects or Equipment.
                            </p>
                            <p>
                                We have developed customized and innovative service options to enable you
                                make that smart decision that best suits your needs. We consciously
                                maintain and promote our freight fowarding objectives of speed,
                                reliability and cost efficiency. These are achieved through our
                                experinced and constantly trained professional manpower.
                            </p>
                            <p>
                                Corporate entities and organisations that are involved in import and
                                export operations will need us one way or another. We are involved in
                                wide range of freight fowarding services as thus:
                            </p>
                        </div>
                    </section>
                </section>
            </div>
        )
    }
}

export default FreightForwarding
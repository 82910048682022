import React from 'react';
import '../App.css';
import '../css/styles.css'
import '../css/aboutus.css';
import { HeaderImage } from '../common/carousel';
import { NavLink } from 'react-router-dom';

class WhatWeDo extends React.Component {
    render() {
        return(
            <div>
                <HeaderImage image="../images/bannerImg_About.jpg" alt="about-us-img" />

                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                      <li className="breadcrumb-item nonactive-page" aria-current="page"><NavLink exact className="breadcrumb-link" to="/"> Home</NavLink></li>
                      <li className="breadcrumb-item active-page" aria-current="page">About us - What we do</li>
                  </ol>
                </nav>
                <section id="about-us">
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <h1 className="intro-header">About us</h1>
                      <ul className="about-us">
                        <li><NavLink exact className="nonactive-page" to="/about-us/our-mission">Our Mission and Values</NavLink></li>
                        <li>What we do</li>
                      </ul>
                    </div>
                    <div className="col-9 aboutus-column">
                      <h4 className="aboutus-title">
                        Our continuous investment in people, facilities and technology
                        sustains our corporate objectives of professionalism and efficiency.
                        This in turn maintains our hallmark superior service delivery in
                        the industry.
                      </h4>
                      <h4>
                        Services
                      </h4>
                      <div className="aboutus-statement">
                        <p> Our services provide the smartest, most efficient, most reliable
                          and cost effective solutions to our clients' need.The services
                          we offer include:
                        </p>
                        <ul className="aboutus-statement">
                          <li>Freight forwarding</li>
                          <li>Haulage/Logistics</li>
                          <li>Warehousing and Inventory Management</li>
                          <li>Maritime Consultancy Services</li>
                          <li>Bonded Container Terminal Operations</li>
                          <li>Shipping</li>
                        </ul>

                        <h4>
                          Resources
                        </h4>
                        <ul className="aboutus-statement">
                          <li>
                            Seamap group has about 70 staff with a vast and diverse range of
                            experience. With such strength, the prompt clearing
                            and delivery of goods becomes easy
                          </li>
                          <li>
                            To facilitate quick delivery of goods and services, Seamap has
                            thirty-five trucks that convey goods to our client's warehouses.
                          </li>
                          <li>
                            Seamap operates a bonded terminal at Amuwo-Odofin Industrial
                            Layout with a large expanse of land in Lagos State.
                          </li>
                        </ul>
                        <p className="aboutus-statement">
                          The relationship of the company, its management staff with the
                          Nigeria Customs Service and other Government Agencies was built
                          on strong foundation such that some of the policies in clearing of
                          goods and services by the Federal Government have the input of the
                          company.
                        </p>
                      </div>
                    </div>
                  </div>
              </section>
            </div>
        )
    }
}

export default WhatWeDo;
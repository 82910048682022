import React from 'react'
import '../css/styles.css'
import '../css/aboutus.css'
import { HeaderImage } from '../common/carousel';
import { NavLink } from 'react-router-dom'

class OurMission extends React.Component {
    render() {
        return (
            <div>
                <HeaderImage image="../images/bannerImg_About.jpg" alt="about-us-img" />

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item nonactive-page" aria-current="page"><NavLink exact className="breadcrumb-link" to="/"> Home</NavLink></li>
                        <li className="breadcrumb-item active-page" aria-current="page">About us - Our Mission and Values</li>
                    </ol>
                </nav>

                <section id="about-us">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                        <h1 className="intro-header">About us</h1>

                        <ul className="about-us">
                            <li>Our Mission and Values</li>
                            <li><NavLink exact className="nonactive-page" to="/about-us/what-we-do">What we do</NavLink></li>
                        </ul>
                        </div>
                        <div className="col-9 aboutus-column">
                        <h4 className="aboutus-title">
                            Seamap Nigeria Limited is a limited liability company
                            incorporated under company and allied matters act in 1992 by the
                            corporate affairs commission.
                        </h4>

                        <h4>
                            Our Mission
                        </h4>
                        <p className="aboutus-statement">
                            To provide excellent, world-class services effectively and efficiently
                            to our esteemed clients. This is made possible by strict adherence
                            to our values.
                        </p>

                        <h4>
                            Our Values
                        </h4>
                        <p className="aboutus-statement">
                            We put our clients' needs and employees' well-being at the center of
                            every decision we make and every policy we introduce. We:
                        </p>
                        <ul className="aboutus-statement">
                            <li>
                                Guarantee integrity of our employees in every project we work on
                            </li>
                            <li>
                                Promote collaboration between our employees and clients
                                to deliver the best quality services
                            </li>
                            <li>
                                Ensure punctuality in our deliveries. This is facilitated by efficient
                                allocation of our resources.
                            </li>
                        </ul>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default OurMission
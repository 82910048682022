import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <div>
                <footer>
                    <p><i className="far fa-copyright"></i>2011-2027 Sea Map Group. All rights reserved</p>
                </footer>
            </div>
        )
    }
}

export default Footer
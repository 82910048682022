import React from 'react';
import '../css/styles.css';
import '../css/services.css';
import { HeaderImage } from '../common/carousel';
import { NavLink } from 'react-router-dom';


class Bct extends React.Component {
    render() {
        return (
            <div>
                <section className="service">
                    <HeaderImage image="../images/services/bct_banner.jpg" alt="about-us-img" />
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item nonactive-page" aria-current="page"><NavLink exact className="breadcrumb-link" to="/"> Home</NavLink></li>
                        <li className="breadcrumb-item active-page" aria-current="page">Services - Bonded Container Terminal</li>
                        </ol>
                    </nav>

                    <section id="introduction">
                        <h1 className="services-header">
                            Bonded Container Terminal
                        </h1>
                        <div className="services-body">
                            <p>
                                Sea Map Nigeria Limited recently secured a provisional license to
                                operate a bonded container terminal, strategically located at
                                Amuwo-Odofin Industrial Layout, Lagos State, Nigeria. Completion of
                                the project which is expected in the next few months will most
                                importantly convey on the company the ability to serve our clients
                                better. Furthermore it will place us in the right pedestal to
                                partner the nation to decongest the ports while helping to
                                consolidate our position in Nigeria's maritime industry.
                            </p>
                            <p>
                                The huge capital investment expended and being expended for this
                                project will bring to life a modern off-dock container terminal
                                that will bring huge benefits to the nigerian maritime industry.
                                Enormous cargo capacity of the facility aided by modern equipment,
                                cutting edge technology and manpower expertise will immensely
                                support the speedy decongestion the main ports, improve turn-around
                                time of vessels as well as promote faster delivery of cargoes to
                                consignees.
                            </p>
                            <p>
                                Services to be rendered in this facility include but is not limited to:
                                <ul>
                                    <li>Transit warehousing for import and export cargo</li>
                                    <li>Bonded warehousing for clients</li>
                                    <li>Customs clearance of cargoes</li>
                                </ul>
                            </p>
                        </div>

                    </section>
                </section>
            </div>
        )
    }
}

export default Bct
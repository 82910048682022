import React from 'react';
import '../App.css';
import '../css/contact.css';
import emailjs from 'emailjs-com';
import { NavLink } from 'react-router-dom';
import Spinner from '../common/spinner';
import { processStatus } from '../common/constants';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      sendStatus: ''
    }
  }

  renderSpinner() {
    return (
      <div>
        <Spinner processStatus={this.state.sendStatus}/>
      </div>
    )
  }

  render() {
    return (
      <div className="contact">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item nonactive-page" aria-current="page"><NavLink exact className="breadcrumb-link" to="/"> Home</NavLink></li>
            <li className="breadcrumb-item active-page" aria-current="page">Contact Us</li>
          </ol>
        </nav>

        <section id="contact-info">
          <div className="contact-body info">
            <div className="address">
              <h3>Address:</h3>
              <p>Plot C23, Paache Close,</p>
              <p>Amuwo-Odofin Industrial Layout,</p>
              <p>Amuwo-Odofin</p>
              <p>Lagos State, Nigeria.</p>
            </div>

            <div className="tel">
              <h3>Tel No: </h3>
              <p>234-17768413</p>
            </div>

            <div className="email">
              <h3>Email:</h3>
              <p>support@seamapgroup.com;  seamapgroup@yahoo.com</p>
            </div>
          </div>

        </section>

        <section id="form">
          <div className="contact-body">
            <h2>Enquiries</h2>
            <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
              <div className="form-group">
                <label htmlFor="first-name" className="label">First Name</label>
                <input type="text" className="form-control" value={this.state.firstName} 
                id="firstName" onChange={this.onFirstNameChange.bind(this)} required />
              </div>
              <div className="form-group">
                <label htmlFor="last-name" className="label">Last Name</label>
                <input type="text" className="form-control" id="lastName" 
                value={this.state.lastName} onChange={this.onLastNameChange.bind(this)} required />
              </div>
              <div className="form-group">
                <label htmlFor="email" className="label">Email</label>
                <input type="email" className="form-control" id="email" aria-describedby="emailHelp" 
                value={this.state.email} onChange={this.onEmailChange.bind(this)} required />
              </div>
              <div className="form-group">
                <label htmlFor="message" className="label">Message</label>
                <textarea className="form-control" id="message" rows="3" 
                value={this.state.message} onChange={this.onMessageChange.bind(this)} required></textarea>
              </div>
              <button type="submit" className="btn btn-lg btn-block submit" data-toggle="modal" data-target="#modal-spinner">
                Submit
              </button>
              {this.state.firstName && this.state.lastName && this.state.email && this.state.message &&
                this.renderSpinner()
              }
            </form>
          </div>
        </section>
      </div>
  )
  }
  
  onFirstNameChange(event) {
    this.setState({firstName: event.target.value})
  }

  onLastNameChange(event) {
    this.setState({lastName: event.target.value})
  }

  onEmailChange(event) {
    this.setState({email: event.target.value})
  }

  onMessageChange(event) {
    this.setState({message: event.target.value})
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      sendStatus: processStatus.running
    });

    var emailParams = {
      fullName: this.state.firstName + ' ' + this.state.lastName,
      email: this.state.email,
      message: this.state.message
    }

    emailjs.send('contact_service', `${process.env.REACT_APP_EMAIL_TEMPLATE_ID}`, emailParams, `${process.env.REACT_APP_USER_ID}`)
      .then((result) => {
        this.setState({
          sendStatus: processStatus.success
        });
      }, (error) => {
        this.setState({
          sendStatus: processStatus.failed
        });
      });
  }
}

export default Contact;

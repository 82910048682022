import { Component } from 'react';
import '../css/spinner.css';
import '@teamhive/lottie-player';
import { processStatus } from '../common/constants';

 class Spinner extends Component {
    render() {
        return (
            <div>
                <div className="modal fade" id="modal-spinner" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                            </div>
                            <div className="modal-body">
                                {this.props.processStatus === processStatus.running &&
                                    <div>
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-border spinner" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        <div className="spinner-header">
                                            <h3>Sending...</h3>
                                        </div>
                                    </div>
                                }
                                {this.props.processStatus === processStatus.success &&
                                    <div>
                                        <div className="lottie-media center">
                                            <lottie-player src="https://assets4.lottiefiles.com/temp/lf20_5tgmik.json" speed="0.5" background="transparent" autoplay></lottie-player>
                                        </div>
                                        <div className="spinner-header">
                                            <h3>Email sent</h3>
                                        </div>
                                    </div>
                                }
                                {this.props.processStatus === processStatus.failed &&
                                    <div>
                                        <div className="lottie-media center">
                                            <lottie-player src="https://assets3.lottiefiles.com/temp/lf20_QrtGro.json"  background="transparent"  speed="1" autoplay></lottie-player>
                                        </div>
                                        <div className="spinner-header">
                                            <h3>Sending failed. Please try again or contact us by other means</h3>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Spinner
import React from 'react'

function CarouselForPage({firstPicture, secondPicture, thirdPicture, fourthPicture}) {
    return (
        <div id="title-slide" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#title-slide" data-slide-to="0" class="active"></li>
              <li data-target="#title-slide" data-slide-to="1"></li>
              <li data-target="#title-slide" data-slide-to="2"></li>
              <li data-target="#title-slide" data-slide-to="3"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img class="title-image" src={firstPicture} alt="image03"/>
                </div>
                <div class="carousel-item">
                    <img class="title-image" src={secondPicture} alt="image05"/>
                </div>
                <div class="carousel-item">
                    <img class="title-image" src={thirdPicture} alt="image06"/>
                </div>
                <div class="carousel-item">
                    <img class="title-image" src={fourthPicture} alt="image07"/>
                </div>
            </div>
                <a class="carousel-control-prev" href="#title-slide" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#title-slide" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
        </div>
    )
}

function HeaderImage({image, alt}) {
    return (
     <section id="image">
       <img class="title-image" src={image} alt={alt} />
     </section>
    )
}

export { CarouselForPage, HeaderImage}